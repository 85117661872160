<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <portal-target name="pageTitle">
            <h2 class="content-header-title float-left mb-0">
              {{ $store.getters['app/currentPageTitle'] || $route.meta.pageTitle }}
            </h2>
          </portal-target>
        </b-col>
        <b-col cols="12">
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $store.getters['app/currentBreadcrumbs'].length > 0 ? $store.getters['app/currentBreadcrumbs'] : $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text || item.fromStore }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
  },
}
</script>

<style scoped>
.breadcrumb {
  padding: 0.3rem 0rem;
}
.content .content-wrapper .content-header-title {
  border-right: 0px solid #d6dce1;
}
</style>
